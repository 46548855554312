import React from "react"
import { graphql } from "gatsby"

import Layout from "@components/Layout"
import SEO from "@components/Seo"
import BackgroundImg from "@components/BackgroundImg"
import {
  Intro,
  PreContest,
  LeanMass,
  Transformation,
  OneOff,
} from "@components/Programs"

const IndexPage = ({ data }) => {
  const banner = data.banner.childImageSharp.fluid
  const precontest = data.precontest.childImageSharp.fluid
  const leanMass = data.leanMass.childImageSharp.fluid
  const transformation = data.transformation.childImageSharp.fluid
  const oneoff = data.oneoff.childImageSharp.fluid

  return (
    <Layout fluid={banner} alt={"Neil Hill Coaching"} height={300}>
      <SEO title="Home" />
      <Intro />
      <BackgroundImg fluid={precontest} alt={`Pre-Contest Prep Coaching`} />
      <PreContest />
      <BackgroundImg fluid={leanMass} alt={`Lean Mass Coaching`} />
      <LeanMass />
      <BackgroundImg fluid={transformation} alt={`Transformation Coaching`} />
      <Transformation />
      <BackgroundImg fluid={oneoff} alt={`One-Off Coaching`} />
      <OneOff />
    </Layout>
  )
}

export const query = graphql`
  query IndexQuery {
    banner: file(relativePath: { eq: "new_home_1440x400.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    precontest: file(relativePath: { eq: "pre_contest_1790x325.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1790) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    transformation: file(relativePath: { eq: "transformation_1790x325.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1790) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    leanMass: file(relativePath: { eq: "lean_mass_1790x325.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1790) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    oneoff: file(relativePath: { eq: "one_off_coaching_1790x325.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1790) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default IndexPage
