import React from "react"
import Img from "gatsby-image"
import Styled from "styled-components"

const BgImage = Styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;

  & > img {
    object-fit: cover !important;
    object-position: 0% 0% !important;
    font-family: 'object-fit: cover !important; object-position: 0% 0% !important;';
  }
`

const BackgroundImg = ({ fluid, alt }) => <BgImage fluid={fluid} alt={alt} />

export default BackgroundImg
