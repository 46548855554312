import React from "react"
import { navigate } from "gatsby"

import { Container } from "./SharedStyles"
import { Button } from "../Form"

const Transformation = () => (
  <Container>
    <h2>TRANSFORMATION PROGRAM</h2>
    <h3>Who’s this program for?</h3>
    <p>
      The 12-week transformation program is one of the most popular programs
      with over 60% of online coaching clients opting for this package.
    </p>
    <p>
      If you want to radically transform your health and body by shedding body
      fat and improving overall body composition whether for health, aesthetic
      reasons or simply to feel better then this is the program for you.
    </p>
    <p>
      Every element of the transformation program is designed by Neil. You’ll
      receive a completely tailored plan suitable for your level of experience,
      body type, and gender as well as personal 1-2-1 contact with Neil for
      regular check-ins and adjustments when necessary.
    </p>
    <h3>What are the results you can expect?</h3>
    <ul>
      <li>Reduction in body fat</li>
      <li>Gains in lean muscle</li>
      <li>Improved health</li>
      <li>Elevated energy</li>
      <li>Increased strength</li>
      <li>Better endurance</li>
      <li>Improved digestion</li>
      <li>Reduction in injuries</li>
      <li>Better gut health and much more!</li>
      <li>What do you get?</li>
      <li>In-depth questionnaire</li>
      <li>Bespoke 12-week training program designed by Neil</li>
      <li>Bespoke cardio routine if required designed by Neil</li>
      <li>Bespoke 12-week nutrition plan designed by Neil</li>
      <li>Bespoke supplement protocol designed by Neil</li>
      <li>
        Adjustments to training, nutrition, and supplements when necessary
      </li>
      <li>
        Weekly or daily check-ins with Neil via email, audio, and video calls
      </li>
      <li>Motivation and accountability</li>
    </ul>
    <h6>
      Please note that longer term programs beyond 12-weeks are available on
      request.
    </h6>
    <hr style={{ borderTop: "1px solid #d41f1f", width: "80%" }} />
    <Button onClick={() => navigate("/register")}>
      Ready to start your transformation today?
    </Button>
  </Container>
)

export default Transformation
