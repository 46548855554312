import React from "react"
import { navigate } from "gatsby"

import { Container } from "./SharedStyles"
import { Button } from "@components/Form"

const OneOff = () => (
  <Container>
    <h2>ONE OFF TRAINING, NUTRITION & SUPPLEMENT PROGRAM</h2>
    <h3>Who's this program for?</h3>
    <p>
      If you want a bespoke training, nutrition, and supplement program tailored
      to you and your goals, but your budget can’t quite stretch to the other
      programs then this is the plan for you.
    </p>
    <p>
      Taking your training and nutrition to a whole new level requires
      experience and a proven track record. Neil is one of the world’s leading
      coaches and he’s known for getting results.
    </p>
    <p>
      Statistically, most people who lift weights don’t follow a properly
      structured program that uses the principles of periodization and overload.
      This is a fatal flaw in anybody’s training program which means you’re
      missing out on building extra muscle mass. With a tailored Y3T training
      program it’s all done for you so there’s no more guessing.
    </p>
    <p>
      Many people struggle to get their diet and supplements right due to the
      many confusing messages out there. As a result, progress is limited and
      money and time are wasted. With a tailored Y3T nutritional and supplement
      plan it’s all structured for you and geared towards your personal goals.
      All you have to do is follow the plan.
    </p>
    <h3>What are the results you can expect?</h3>
    <ul>
      <li>Reduction in body fat</li>
      <li>Gains in lean muscle</li>
      <li>Improved health</li>
      <li>Elevated energy</li>
      <li>Increased strength</li>
      <li>Better endurance</li>
      <li>Improved digestion</li>
      <li>Reduction in injuries</li>
      <li>Better gut health and much more!</li>
      <li>What do you get?</li>
      <li>In-depth questionnaire</li>
      <li>Bespoke 12-week training program designed by Neil</li>
      <li>Bespoke cardio routine if required designed by Neil</li>
      <li>Bespoke nutrition plan designed by Neil</li>
      <li>Bespoke supplement protocol designed by Neil</li>
      <li>
        15 min video call with Neil to discuss your plan and answers questions
      </li>
    </ul>
    <h6>
      Please be aware that once you receive your plan and had your call there
      will be no further contact. This is to help keep this program affordable,
      but you can be confident that your plan will be done to the highest
      standard.
    </h6>
    <hr style={{ borderTop: "1px solid #d41f1f", width: "80%" }} />
    <Button onClick={() => navigate("/register")}>
      Ready to start your one-off training, nutrition, and supplement plan
      today?
    </Button>
  </Container>
)

export default OneOff
