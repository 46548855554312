import React from "react"
import { navigate } from "gatsby"

import { Container } from "./SharedStyles"
import { Button } from "@components/Form"

const Intro = () => (
  <Container>
    <h2>TRANSFORM YOUR BODY & HEALTH WITH Y3T ONLINE COACHING</h2>
    <h3>
      MAXIMUM RESULTS <span>|</span> MINIMUM TIME <span>|</span> LOCATION
      INDEPENDENT
    </h3>
    <p>
      The ultimate way to fast track and maximize your results is to have 1-2-1
      online coaching directly with Neil Hill. You’ll have the very same
      guidance and treatment as fellow Y3T athletes such as 7 times 212 Mr.
      Olympia champion Flex Lewis, Arnold Classic champion Alex Cambronero and
      WWE global superstar Roman Reigns.
    </p>
    <p>By working with Neil directly you're going to:</p>
    <ul>
      <li>Remove all of the guesswork</li>
      <li>Have a bespoke plan created by Neil himself</li>
      <li>
        Take away the stress as Neil will advise on adjustments at the right
        time
      </li>
    </ul>
    <p>
      With online coaching, nothing is left to chance – it’s all methodically
      broken down and given to you to follow, step by step. This includes your
      training plan, cardio, diet, supplement protocol, and any necessary
      adjustments along the way.
    </p>
    <p>
      It doesn’t matter if you’re a pro bodybuilder, amateur athlete, or an
      everyday person – Neil’s online coaching is going to help you achieve your
      personal goals.
    </p>
    <p>
      For example, if you’re looking to put on some quality lean muscle or an
      athlete in the off season then check out the LEAN MASS PROGRAM. If it’s
      more about fat loss for you, whether for health or aesthetic reasons then
      look at the TRANSFORMATION PROGRAM. Maybe you’ve aspirations to hit the
      stage and achieve your pro card then it’s the PRE CONTEST PREP PROGRAM you
      need to look at. Lastly, if you still want all the elements of a bespoke
      plan but your budget doesn’t stretch to include weekly check-ins and
      adjustments then read up on the ONE-OFF TRAINING, NUTRITION & SUPPLEMENT
      PROGRAM.
    </p>
    <p>
      There’s also this misconception that Neil only coaches’ elite athletes and
      men only - this is not true. Neil also coaches many corporate business
      people, everyday men and women, as well as members of royal families and
      celebrities from around the globe. These highly respected people entrust
      Neil with their health and fitness, which gives you the confidence to do
      the same.
    </p>
    <hr style={{ borderTop: "1px solid #d41f1f", width: "80%" }} />
    <h3>IMPORTANT</h3>
    <p>
      There will be cheaper online coaching programs out there, but these will
      likely be cookie-cutter plans where a faceless individual or even worse a
      piece of software sends out low level, totally generalized plans by the
      dozen. Neil’s online coaching is fully designed by him and bespoke to you.
    </p>
    <p>
      The online coaching industry is also rife with social media influencers
      and celebrity athletes who give you the impression they are personally
      coaching you. Often this is not the case, and instead, it’s been
      outsourced.
    </p>
    <p>
      When you become a Y3T online coaching client you can be sure Neil really
      is the person who’s coaching you. You’ll have personal access to Neil’s
      WhatsApp, as well as the ability to have video and audio calls when
      necessary. Do other coaches offer this level of personal interaction?
      Highly unlikely! Why? because they’re not the ones coaching you.
    </p>
    <hr style={{ borderTop: "1px solid #d41f1f", width: "80%" }} />
    <Button onClick={() => navigate("/register")}>Begin Registration?</Button>
  </Container>
)

export default Intro
