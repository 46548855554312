import Styled from "styled-components"

import { GenerateClamp } from "@utils"

const Container = Styled.div`
    width: 80%;
    padding: 1rem;
    margin: 0 auto 2rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2, h3, p, ul {
        margin-bottom: 2rem;
    }
    ul {
        margin-top: -2rem;
    }
    h2 {
        text-align: center;
        font-size: ${GenerateClamp(360, 1200, 1.5, 2.5)};
    }
    h3 {
        text-align: center;
        font-size: ${GenerateClamp(360, 1400, 1, 1.5)};
        span {
            color: ${({ theme }) => theme.primary};
            margin-left: 0.25rem;
            margin-right: 0.25rem;
        }
    }
    p {
        font-size: ${GenerateClamp(360, 1400, 1, 1.5)};
    }
    ul {
        li {
            &::marker {
                color: ${({ theme }) => theme.primary};
            }
        }
    }
    @media screen and (max-width: 600px) {
        width: 90%;
    }
`

export { Container }
