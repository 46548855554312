import React from "react"
import { navigate } from "gatsby"

import { Container } from "./SharedStyles"
import { Button } from "@components/Form"

const PreContest = () => (
  <Container>
    <h2>PRE-CONTEST PREP PROGRAM</h2>
    <h3>Who’s this program for?</h3>
    <p>
      The 12-week pre-contest prep program is for those who want to compete
      whether amateur or professional.
    </p>
    <p>
      Pre-contest preparation can be a very daunting experience and it’s easy to
      get wrong. This is why so many people from around the world recruit the
      expertise of Neil, who has coached countless athletes to the very pinnacle
      of stage competition.
    </p>
    <p>
      Every element of the pre-contest prep program is designed by Neil. You’ll
      receive a completely tailored plan suitable for your level of experience,
      body type, and gender as well as personal 1-2-1 contact with Neil for
      regular check-ins and adjustments when necessary.
    </p>
    <h3>What are the results you can expect?</h3>
    <ul>
      <li>Reduction in body fat</li>
      <li>Gains in lean muscle</li>
      <li>Improved health</li>
      <li>Elevated energy</li>
      <li>Increased strength</li>
      <li>Better endurance</li>
      <li>Improved digestion</li>
      <li>Reduction in injuries</li>
      <li>Better gut health and much more!</li>
      <li>What do you get?</li>
      <li>In-depth questionnaire</li>
      <li>Bespoke 12-week training program designed by Neil</li>
      <li>Bespoke cardio routine if required designed by Neil</li>
      <li>Bespoke 12-week nutrition plan designed by Neil</li>
      <li>Bespoke supplement protocol designed by Neil</li>
      <li>
        Adjustments to training, nutrition, and supplements when necessary
      </li>
      <li>
        Weekly or daily check-ins with Neil via email, audio, and video calls
      </li>
      <li>Motivation and accountability</li>
      <li>Peak week protocol</li>
    </ul>
    <h6>
      Please note that longer-term programs beyond 12-weeks are available upon
      request.
    </h6>
    <hr style={{ borderTop: "1px solid #d41f1f", width: "80%" }} />
    <Button onClick={() => navigate("/register")}>
      Ready to start building your stage physique today?
    </Button>
  </Container>
)

export default PreContest
