import React from "react"
import { navigate } from "gatsby"

import { Container } from "./SharedStyles"
import { Button } from "@components/Form"

const LeanMass = () => (
  <Container>
    <h2>LEAN MASS PROGRAM</h2>
    <h3>Who’s this program for?</h3>
    <p>
      The 12-week lean mass program is for those who want to build lean quality
      muscle and for athletes in their off season. Neil has a solid reputation
      for achieving this with his professional and amateur athletes as well as
      everyday clients.
    </p>
    <p>
      With this program, there will be a focus on both forms of muscle
      hypertrophy namely myofibrillar and sarcoplasmic along with hormonal
      optimization as a means of forcing the body into the most anabolic state
      possible.
    </p>
    <p>
      Every element of the lean mass program is designed by Neil. You’ll receive
      a completely tailored plan suitable for your level of experience, body
      type, and gender as well as personal 1-2-1 contact with Neil for regular
      check-ins and adjustments when necessary.
    </p>
    <h3>What are the results you can expect?</h3>
    <ul>
      <li>Reduction in body fat</li>
      <li>Gains in lean muscle</li>
      <li>Improved health</li>
      <li>Elevated energy</li>
      <li>Increased strength</li>
      <li>Better endurance</li>
      <li>Improved digestion</li>
      <li>Reduction in injuries</li>
      <li>Better gut health and much more!</li>
      <li>What do you get?</li>
      <li>In-depth questionnaire</li>
      <li>Bespoke 12-week training program designed by Neil</li>
      <li>Bespoke cardio routine if required designed by Neil</li>
      <li>Bespoke 12-week nutrition plan designed by Neil</li>
      <li>Bespoke supplement protocol designed by Neil</li>
      <li>
        Adjustments to training, nutrition, and supplements when necessary
      </li>
      <li>
        Weekly or daily check-ins with Neil via email, audio, and video calls
      </li>
      <li>Motivation and accountability</li>
    </ul>
    <h6>
      Please note that longer term programs beyond 12-weeks are available on
      request.
    </h6>
    <hr style={{ borderTop: "1px solid #d41f1f", width: "80%" }} />
    <Button onClick={() => navigate("/register")}>
      Ready to start building some lean mass today?
    </Button>
  </Container>
)

export default LeanMass
